import React, { Component } from 'react';
import { Tooltip } from 'antd';
import numeral from 'numeral';

import getPercentage from '../../utils/getPercentage';
import './DividedBar.scss';

export default class DividedBar extends Component {
    render() {
        const { questionLabel, questionPts, maxTotalPts } = this.props;
        const totalPts = questionPts;
        const totalBarWidth = getPercentage(totalPts, 0, maxTotalPts);
        let leftBarWidth = questionPts / totalPts;

        return (
        	<div className="DividedBar" style={{ width: `${totalBarWidth * 100}%` }}>
        		<div 
        			className="left-bar"
        			style={{ width: `${leftBarWidth * 100}%` }}
        		>
                </div>
        	</div>
        );
    }
}

DividedBar.defaultProps = {
	questionPts: 0,
	currencyBalancePts: 0,
	maxTotalPts: 0
}