export default {
	'en': {
		'classLabel': 'Class',
		'rank': 'Rank',
		'score': 'Game Points',
		'question': 'Eco Power',
		'currencyBalance': 'Balance (Excluding Game Points)',
		'totalPoints': 'Total Points',
		'rankChange': 'Rank Change',
	},
	'tc': {
		'classLabel': '班別',
		'rank': '排名',
		'score': '遊戲積分',
		'question': '環保力量',
		'currencyBalance': '餘額（不計算遊戲積分）',
		'totalPoints': '總積分',
		'rankChange': '排名變化',
	}
}