import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import cx from 'classnames';
import _ from 'lodash';

import * as actions from '../../services/translation/actions';
import { translationOptions } from '../../utils/options';
import leftLogo from './left_logo.png';
import MainLogo from './main_logo.png';
import rightLogo from './right_logo.png';
import logo from './logo.svg';
import combineLogo from './logo-combine.png';
import './Header.scss';

import scripts from './translations.js';

export class Header extends Component {
    render() {
        const { isKiosk, updateTranslationSetting, isMultiLingual, langCode, isBackBtnAvailable, isLoginBtnAvailable, selectedClass, onBackBtnClick, onLoginBtnClick, onLogoutBtnClick } = this.props;
        // const languageBtns = _.map(translationOptions, (option) =>
        //     <div 
        //         key={option.value}
        //         className={cx({
        //             "language-btn": true,
        //             "bw-btn": true,
        //             "selected": langCode === option.value
        //         })}
        //         onClick={(e) => updateTranslationSetting(option.value)}
        //     >
        //         {option.label}
        //     </div>
        // );

        return (
            <div className="Header">
            	<div className="top-cloud-wrapper">
		            <div className="top-cloud">
		                <div className="Cloud Cloud1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.17 98.9"><path className="cls-1" d="M188,51.07a16.08,16.08,0,0,0-6.48,1.39A17.57,17.57,0,0,0,160.8,41.29a18.53,18.53,0,0,0-22.44-15.76,9.71,9.71,0,0,0,.33-2.49,9.61,9.61,0,0,0-13.31-8.82,25.19,25.19,0,0,0-45.21-.29,14.44,14.44,0,0,0-2.73-.26,13.71,13.71,0,0,0-13.1,17.66c-.19,0-.37,0-.57,0A29.57,29.57,0,0,0,38.26,46.12a20.86,20.86,0,0,0-23.19,8.66,11.34,11.34,0,0,0-3.47-.51,11.65,11.65,0,1,0,.09,23.29,11.35,11.35,0,0,0,3.05-.43,20.85,20.85,0,0,0,29.81,6.21A29.46,29.46,0,0,0,64,90.55a28.11,28.11,0,0,0,2.92-.16,18.79,18.79,0,0,0,32-.86,33.63,33.63,0,0,0,7.59.86,32.58,32.58,0,0,0,13-2.72,22,22,0,0,0,37.15-10.35,10.41,10.41,0,0,0,19.07.18A16.11,16.11,0,1,0,188,51.07Z"></path></svg></div>
		                <div className="Cloud Cloud1-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.17 98.9"><path className="cls-1" d="M188,51.07a16.08,16.08,0,0,0-6.48,1.39A17.57,17.57,0,0,0,160.8,41.29a18.53,18.53,0,0,0-22.44-15.76,9.71,9.71,0,0,0,.33-2.49,9.61,9.61,0,0,0-13.31-8.82,25.19,25.19,0,0,0-45.21-.29,14.44,14.44,0,0,0-2.73-.26,13.71,13.71,0,0,0-13.1,17.66c-.19,0-.37,0-.57,0A29.57,29.57,0,0,0,38.26,46.12a20.86,20.86,0,0,0-23.19,8.66,11.34,11.34,0,0,0-3.47-.51,11.65,11.65,0,1,0,.09,23.29,11.35,11.35,0,0,0,3.05-.43,20.85,20.85,0,0,0,29.81,6.21A29.46,29.46,0,0,0,64,90.55a28.11,28.11,0,0,0,2.92-.16,18.79,18.79,0,0,0,32-.86,33.63,33.63,0,0,0,7.59.86,32.58,32.58,0,0,0,13-2.72,22,22,0,0,0,37.15-10.35,10.41,10.41,0,0,0,19.07.18A16.11,16.11,0,1,0,188,51.07Z"></path>/svg&gt;</svg></div>
		                <div className="Cloud Cloud2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.29 72"><path className="cls-1" d="M107,10.41C104.62,4.36,98,0,90.14,0A18.85,18.85,0,0,0,75.82,6.22,18.82,18.82,0,0,0,61.51,0,19.56,19.56,0,0,0,50.42,3.34a13.78,13.78,0,0,0-5.12,5C43,4.49,39.2,2,35,2,29,2,24,6.92,22.52,13.59a14.19,14.19,0,0,0-5.46-1.09C7.64,12.5,0,21.76,0,33.17c0,10.1,6,18.5,13.88,20.31C16.77,59.75,22.31,64,28.67,64a14.28,14.28,0,0,0,6.23-1.45c3,5.68,8.27,9.45,14.25,9.45a14.8,14.8,0,0,0,9-3.16,10.72,10.72,0,0,0,5.09,1.3c4.62,0,8.66-3,10.85-7.53A10.65,10.65,0,0,0,78.8,63.7c5.76,0,10.6-4.69,12.1-11.09a19.93,19.93,0,0,0,2.91.22c9.25,0,16.81-6.21,17.38-14,6-2.38,10.1-7.58,10.1-13.61C121.29,17.8,115.12,11.68,107,10.41Z"></path></svg></div>
		                <div className="Cloud Cloud3"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.92 68"><path className="cls-1" d="M111.93,10.5a27.15,27.15,0,0,0-7.68,1.09C102.2,4.92,95.15,0,86.76,0A19.17,19.17,0,0,0,72.21,6.32,19.17,19.17,0,0,0,57.66,0c-7,0-13.14,3.47-16.13,8.53A20.1,20.1,0,0,0,31.66,6c-8,0-14.72,4.43-17.14,10.58C6.26,17.87,0,24.09,0,31.58,0,37.71,4.2,43,10.26,45.42c.58,8,8.26,14.28,17.66,14.28a20.16,20.16,0,0,0,6.57-1.09,18.37,18.37,0,0,0,15.26,7.53,20.19,20.19,0,0,0,7.16-1.3A26.55,26.55,0,0,0,69.61,68a25.48,25.48,0,0,0,18.08-7.17A27.33,27.33,0,0,0,95.61,62c8.94,0,16.72-4.25,20.79-10.52,11.11-1.81,19.52-10.21,19.52-20.31C135.92,19.76,125.18,10.5,111.93,10.5Z"></path></svg></div>
		            </div>
            	</div>
				<div className="navbar">
					<div className="navbar-wrapper">
						<div className="main-logo" style={isKiosk ? { display: 'none' } : null}>
							<img src={logo} alt=""/>
						</div>
						<div className="logo-gp" style={isKiosk ? { display: 'none' } : null}>
							<img className="sub" src={combineLogo} alt="Reconnect" style={{ padding: '10px 0px' }}/>
						</div>
						<div className="btn-section">
							<div className="left-section">
								{
									isBackBtnAvailable &&
									<div className="bw-btn back-btn" onClick={(e) => onBackBtnClick(e)}>
										<Icon type="rollback" />Back
									</div>
								}
							</div>
							<div className="right-section">
								<div className="class-label">
									{ selectedClass && `${scripts[langCode].class}: ${selectedClass}` }
								</div>
								{
									isLoginBtnAvailable && selectedClass &&
									<div className="login-btn" onClick={(e) => onLogoutBtnClick(e)}>
										{ scripts[langCode].logout }
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = (state) => ({
    langCode: state.translation.langCode
})

export default connect(mapStateToProps, actions)(Header);