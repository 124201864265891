export default {
    'en': {
        'ranking': 'Ranking',
        'enterGame': 'Start Game',
        'modalCompetitionSubmissionErrMessage': 'Submission Failed.',
        'modalCompetitionSubmissionSuccessMessage': 'Submission Successful.',
        'ok': 'OK',
        'submission': 'Submit your work',
        'gameInstructionTitle': 'Creating Your Own Garbage Bin Competition',
        'howToUpload': 'How to submit?',
        'gameInstructionDescription': 'Students are encouraged to design garbage bins suitable for their classes to replace the original garbage bins which vary in sizes in their classrooms, and choose appropriate designated garbage bag. This reduce the waste produced by the removal of garbage bags even when it is not fully utilized because of hygiene reasons.',
    },
    'tc': {
        'ranking': '排名',
        'enterGame': '開始遊戲',
        'modalCompetitionSubmissionErrMessage': '提交不成功。',
        'modalCompetitionSubmissionSuccessMessage': '提交成功。',
        'ok': '明白',
        'submission': '提交作品',
        'howToUpload': '如何上傳你的作品？',
        'gameInstructionTitle': '垃圾桶設計比賽',
        'gameInstructionDescription': '此比賽鼓勵同學設計出適合自己班別的垃圾桶，和選擇合適的垃圾袋，以取代班內原有體積較大的垃圾桶，減少垃圾袋因為衛生問題，未有裝滿便要清理而造成的浪費。同學通過使用更少、更細的垃圾袋為自己的班別節省更多虛擬幣的同時，亦能實踐源頭減廢。',
    }
}