import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'react-fa';
import cx from 'classnames';

import plasticBag1 from './plastic-bag-1.png';
import plasticBag2 from './plastic-bag-2.png';
import plasticBag3 from './plastic-bag-3.png';
import plasticBag4 from './plastic-bag-4.png';
import plasticBag5 from './plastic-bag-5.png';
import footerEarth1 from './footer-earth-1.svg';
import footerEarth2 from './footer-earth-2.svg';
import footerLogo from './footer-logo.svg';
import slogan from './footer-bg-slogan.png';
import './Footer.scss';

import scripts from './translations.js';

export class Footer extends Component {

    render() {
        const { langCode, isKiosk } = this.props;
        return (
            <div className="Footer">
                <div className="footer-section footer-section-1">
                    <div className="footer-container">
                        <img className="footer-logo" src={footerLogo} alt=""/>
                        <div className={cx('footer-right', { 'hidden': isKiosk })}>
                            <a href="//www.facebook.com/loopprogram">
                                <div className="icon-btn">
                                    <Icon name="facebook-f"/>
                                </div>
                            </a>
                            <a href="//www.youtube.com/user/ReconnectEnvironment">
                                <div className="icon-btn">
                                    <Icon name="youtube"/>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="footer-earth">
                        <div className="earth-wrapper">
                            <img className="layer layer1 rotating" src={footerEarth1} alt="" />
                            <img className="layer layer2 rotating" src={footerEarth2} alt="" />
                        </div>
                    </div>
                </div>
                <div className={cx('footer-section footer-section-2', { 'hidden': isKiosk })}>
                    <div className="footer-section-2-wrapper">
                        <p>
                            <a href="//www.reconnect.org.hk/">Reconnect Limited</a> © 2018 All Rights Reserved | Powered By <a href="//www.futureimpactlab.com">Future Impact Lab Limited</a> ｜ <a href="/about.html">About</a>
                        </p>
                    </div>
                </div>
                <div className={cx('footer-section footer-section-3', { 'hidden': isKiosk })}>
                    <p>{scripts[langCode]['disclaimer']}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    langCode: state.translation.langCode
})

export default connect(mapStateToProps)(Footer);