import React, {Component} from 'react';
import {connect} from 'react-redux';
import {matchPath} from 'react-router-dom';
import _ from 'lodash';

import * as actions from '../../../../services/student/actions';
import GamingInterface from './components/GamingInterface/GamingInterface';
import EndGameInterface from './components/EndGameInterface/EndGameInterface';
import './GamingPage.scss';
import OrgGameApi from "../../../../apiClient/api/OrgGameApi";
import getQueryParams from '../../../../utils/getQueryParams.js';

export class GamingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questions: [],
            totalAnsweredQuestions: 0,
            selectedAnswerId: undefined,
            correctAnswer: undefined,
            isAnswerSubmitted: false,
            record: {},
            loaded: false,
        }
    }

    handleAnswerSelect(id) {
        this.setState({selectedAnswerId: id});
    }

    handleAnswerSubmit(e) {
        const {langCode} = this.props;
        const {questions, totalAnsweredQuestions, selectedAnswerId, isAnswerSubmitted} = this.state;

        if (isAnswerSubmitted) {
            this.setState({
                isAnswerSubmitted: false,
                totalAnsweredQuestions: totalAnsweredQuestions + 1,
                selectedAnswerId: undefined,
                correctAnswer: undefined,
            });
        } else {
            let record = this.state.record;

            // set record mcid: answer id
            record[questions[this.state.totalAnsweredQuestions].id] = selectedAnswerId;

            this.setState({
                isAnswerSubmitted: true,
                record: record,
            });
        }
    }

    handleLogoutClick(e) {
        const {updateStudentUserInfo, history, location} = this.props;
        const match = matchPath(location.pathname, {
            path: '/:device/:schoolId/game',
            exact: true,
            strict: false
        })
        const zoomLevel = getQueryParams(location.search)['zoom'] || 1;

        updateStudentUserInfo({class: ''});
        history.push(`/${match.params.device}/${match.params.schoolId}/login?zoom=${zoomLevel}`);
    }

    handlePlayAgainClick(e) {
        this.loadQuestion().then((data) => {
            // reset and load new question
            this.setState(
                {
                    questions: data.mcs,
                    loaded: true,
                    totalAnsweredQuestions: 0,
                    selectedAnswerId: undefined,
                    record: {},
                    correctAnswer: undefined,
                    totalCorrectedQuestions: 0
                },
            )
        });

    }

    componentDidMount() {
        this.loadQuestion().then((data) => {
            this.setState(
                {questions: data.mcs, loaded: true},
            )
        });
    }

    loadQuestion() {
        return new OrgGameApi().orgGameMcs(window.schoolId)
    }

    render() {
        const {langCode, location} = this.props;
        const {questions, totalAnsweredQuestions, selectedAnswerId, isAnswerSubmitted, totalCorrectedQuestions, loaded, record} = this.state;
        const isAllQuestionAnswered = questions.length !== 0 && totalAnsweredQuestions === questions.length;
        const match = matchPath(location.pathname, {
            path: '/:device/:schoolId/game',
            exact: true,
            strict: false
        })
        const zoomLevel = getQueryParams(location.search)['zoom'] || 1;

        if (match.params.device === 'kiosk' && loaded && isAllQuestionAnswered) {
            window.location = `http://www.folio.com.hk/ranking&code=${match.params.schoolId}&zoom=${zoomLevel}`;
        }

        return (
            <div className="GamingPage">
                {/* if questions are not loaded */}
                {
                    !loaded ?
                        <div></div> :
                        null
                }
                {/* if loaded and answered all questions */}
                {
                    match.params.device === 'web' && loaded && isAllQuestionAnswered ?
                        <EndGameInterface
                            langCode={langCode}
                            record={record}
                            onLogoutClick={(e) => this.handleLogoutClick(e)}
                            onPlayAgainClick={(e) => this.handlePlayAgainClick(e)}
                        /> : null
                }
                {/* if loaded and not answered all questions */}
                {
                    loaded && !isAllQuestionAnswered ?
                        <GamingInterface
                            langCode={langCode}
                            question={questions[totalAnsweredQuestions]}
                            selectedAnswerId={selectedAnswerId}
                            isAnswerSubmitted={isAnswerSubmitted}
                            onAnswerSelect={(id) => this.handleAnswerSelect(id)}
                            onAnswerSubmit={(e) => this.handleAnswerSubmit(e)}
                        /> : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    langCode: state.translation.langCode
})

export default connect(mapStateToProps, actions)(GamingPage);