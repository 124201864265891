import React, {Component} from 'react';

import EndGameImage from './end-game-img.png';
import './EndGameInterface.scss';

import script from './translations';
import OrgGameApi from "../../../../../../apiClient/api/OrgGameApi";

export class EndGameInterface extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            totalCorrectQuestions: 0,
            totalIncorrectQuestions: 0,
            amount: 0,
            isDayLimitExceed: false,
        }
    }

    componentDidMount() {
        new OrgGameApi().orgGameMcSubmitAnswers(window.schoolId, this.props.record).then((data) => {
            this.setState({
                loaded: true,
                totalCorrectQuestions: data.result.correct,
                totalIncorrectQuestions: data.result.incorrect,
                amount: data.result.amount / 0.02,
                isDayLimitExceed: false,
            });
        }).catch(()=>{
            this.setState({
                loaded: true,
                isDayLimitExceed: true,
            });
        })
    }

    render() {
        const {langCode, onLogoutClick, onPlayAgainClick} = this.props;
        const {loaded, amount, isDayLimitExceed} = this.state;
        return (
            <div>
                {
                   loaded ?
                        <div className="EndGameInterface">
                            <img src={EndGameImage} alt="End Game"/>
                            {
                                isDayLimitExceed ? <p>{script[langCode].endGameDayLimitExceedMessage}</p> : <p>{script[langCode].endGameMessage(amount)}</p>
                            }
                            <div className="btn-gp">
                                <button className="bw-btn logout-btn" onClick={(e) => onLogoutClick(e)}>{script[langCode].logout}</button>
                                <button className="bw-btn play-again-btn" onClick={(e) => onPlayAgainClick(e)}>{script[langCode].playAgain}</button>
                            </div>
                        </div> : null
                }
            </div>
        );
    }
}

export default EndGameInterface;