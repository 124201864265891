import React from 'react';
import {
    Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';

import SchoolRoute from './routes/SchoolRoute/SchoolRoute';
import ClassRoute from './routes/ClassRoute/ClassRoute';

import BlankPage from './scenes/BlankPage/BlankPage';

import WebInterface from './scenes/WebInterface/WebInterface';
import WebLandingPage from './scenes/WebInterface/scenes/LandingPage/LandingPage';
import WebLoginPage from './scenes/WebInterface/scenes/LoginPage/LoginPage';
import WebGamingPage from './scenes/WebInterface/scenes/GamingPage/GamingPage';

const history = createBrowserHistory();

const webSection = ({ match, ...rest }) => (
    <WebInterface match={match} {...rest}>
		<Switch>
            <Route exact path={`${match.url}/`} component={WebLandingPage}/>
			<Route exact path={`${match.url}/login`} component={WebLoginPage}/>
            <ClassRoute exact path={`${match.url}/game`} component={WebGamingPage}/>
			<Redirect to={`${match.url}/`}/>
		</Switch>
	</WebInterface>
);

const AppRouter = () => (
    <Router history={history}>
    	<Switch>
    		<SchoolRoute path="/web/:schoolId" component={webSection}/>
    		<SchoolRoute path="/kiosk/:schoolId" component={webSection}/>
            <Route path="/" component={BlankPage} />
			<Redirect to="/"/>
        </Switch>
    </Router>
);

export default AppRouter;