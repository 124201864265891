import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';

import RankingTable from '../../../../components/RankingTable/RankingTable';

import bannerImage from './banner.png';
import './LandingPage.scss';
import scripts from './translations.js';

import OrgCompetitionApi from "../../../../apiClient/api/OrgCompetitionApi";
import getQueryParams from '../../../../utils/getQueryParams.js';

export class LandingPage extends Component {
    render() {
        const { langCode, location, history } = this.props;
        const match = matchPath(location.pathname, {
            path: '/:device/:schoolId',
            exact: true,
            strict: false
        });
        const { device, schoolId } = match.params;
        const zoomLevel = getQueryParams(location.search)['zoom'] || 1;
        const isKiosk = device === 'kiosk';


        return (
            <div className="WebLandingPage">
                <div className="banner">
                    <img src={bannerImage} alt=""/>
                    <button className="bw-btn" onClick={() => history.push(`/${device}/${schoolId}/login?zoom=${zoomLevel}`)}>
                        {scripts[langCode].enterGame}
                    </button>
                </div>
                <h2>{scripts[langCode].ranking}</h2>
                <RankingTable langCode={langCode} height={isKiosk ? 160 : 360}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    langCode: state.translation.langCode
})

export default connect(mapStateToProps)(LandingPage);