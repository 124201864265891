import React, { Component } from 'react';
import { Table } from 'antd';
import _ from 'lodash';

import DividedBar from '../DividedBar/DividedBar';
import RankChangeIndicator from '../RankChangeIndicator/RankChangeIndicator';
import GoldCrown from './crown_g.png';
import SilverCrown from './crown_s.png';
import CopperCrown from './crown_c.png';
import './RankingTable.scss';

import script from './translations.js';
import OrgRankingApi from "../../apiClient/api/OrgRankingApi";

export class RankingTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: [
            //     {
            //     id: 1,
            //     class: '4D',
            //     rank: 1,
            //     question_points: 50,
            //     currency_balance: 100,
            //     rank_change: 1
            // }, {
            //     id: 2,
            //     class: '5D',
            //     rank: 2,
            //     question_points: 40,
            //     currency_balance: 90,
            //     rank_change: -1
            // }, {
            //     id: 3,
            //     class: '3D',
            //     rank: 3,
            //     question_points: 50,
            //     currency_balance: 20,
            //     rank_change: 0
            // }, {
            //     id: 4,
            //     class: '2D',
            //     rank: 4,
            //     question_points: 10,
            //     currency_balance: 10,
            //     rank_change: 0
            // }
            ]
        }
    }

    componentDidMount(){
        this.loadRanking();
    }

    loadRanking() {
        new OrgRankingApi().orgRanking(window.schoolId).then((data) => {
            console.log(data);
            let newData = _.sortBy(data.ranking["new"], (o) => {
                return o.balance
            }).reverse();

            let oldData =  _.sortBy(data.ranking["old"], (o) => {
                return o.balance
            }).reverse();

            let dataset = [];
            newData.forEach((v, i)=>{
                let oldIdx = _.findIndex(oldData, function(o) { return o.user.id == v.user.id });

                dataset.push({
                    id: v.user.id,
                    class: v.user.name,
                    rank: i+1,
                    question_points: parseInt(v.answeredMcEarnedSum / 0.02),
                    rank_change: oldIdx - i
                })
            });

            this.setState({ dataset: dataset});
        })
    }

    render() {
        const { langCode, height } = this.props;
        const { dataset } = this.state;
        const maxTotalPts = _.max(_.map(dataset, (d) => d.question_points));
        const columns = [{
            title: '',
            dataIndex: 'crown',
            width: 50,
            align: 'center',
            render: (value, record) => {
                if (record.rank === 1) {
                    return <img className="crown-img" src={GoldCrown} alt="Rank 1"/>;
                } else if (record.rank === 2) {
                    return <img className="crown-img" src={SilverCrown} alt="Rank 2"/>;
                } else if (record.rank === 3) {
                    return <img className="crown-img" src={CopperCrown} alt="Rank 3"/>;
                }
            }
        }, {
            title: script[langCode].rank,
            dataIndex: 'rank',
            width: 70,
            align: 'center'
        }, {
            title: script[langCode].classLabel,
            dataIndex: 'class',
            width: 80,
            align: 'center'
        }, {
            title: script[langCode].score,
            dataIndex: 'question_points',
            width: 150,
            align: 'center',
        }, {
            title: script[langCode].question,
            dataIndex: 'address',
            render: (text, record) => (
                <DividedBar
                    questionLabel={script[langCode].question}
                    questionPts={record.question_points}
                    maxTotalPts={maxTotalPts}
                />
            )
        }];

        return (
            <Table
                className="RankingTable"
                rowKey="id"
                columns={columns}
                dataSource={dataset}
                pagination={false}
                scroll={{ y: height }}
            />
        );
    }
}

RankingTable.defaultProps = {
    langCode: 'en'
}

export default RankingTable;